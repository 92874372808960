<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        账号列表({{name}})
                        <v-spacer></v-spacer>
                        <div>
                            <v-btn color="warning" dark small @click="$router.go(-1)">
                                <v-icon small dark>mdi-arrow-left</v-icon>返回
                            </v-btn>
                        </div>

                    </v-card-title>
                    <v-card-subtitle>
                        <v-form>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3 style="color:red"> 总计:{{totalCount}}人</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!-- <v-col cols="12" md="5">
                  <v-text-field v-model="keys" label="设备账号" placeholder="多个设备账号以 , 隔开" hide-details dense clearable></v-text-field>
                </v-col> -->
                                <v-col cols="12" md="5">
                                    <v-text-field v-model="form.sid" label="推广人ID" hide-details dense clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-btn class="mx-2" dark small color="primary" @click="search">
                                        搜索
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="warning" @click="batchEdit();changeLevel=true;">
                                        会员等级
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="teal" @click="batchEdit();changeEnable=true">
                                        启用禁止
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="pink" @click="batchEdit();changeTime=true">
                                        添加时间
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-data-table :headers="headers" disable-sort :items="robotList" :single-expand='singleExpand' show-expand :loading="loading" hide-default-footer :server-items-length="form.limit" show-select v-model="selected" class="elevation-1">
                            <template v-slot:item.expire="{ item }">
                                <span>{{$moment(item.expire).format("YYYY-MM-DD HH:mm:ss")}}</span>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <span>{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
                            </template>
                            <template v-slot:item.level="{ item }">
                                <span v-if="item.level==2" style="color:#bf7a15">高级会员</span>
                                <span v-if="item.level==1">普通会员</span>
                            </template>
                            <template v-slot:item.addr="{ item }">
                                <span v-if="item.addr=='无'" style="color:red">{{item.addr}}</span>
                                <span v-else>{{item.addr}}</span>
                            </template>
                            <template v-slot:item.enabled="{ item }">
                                <v-switch v-model="item.enabled" @change="changeStatus(item)"></v-switch>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-btn class="mx-2" dark small color="primary" @click="edit(item);changeLevel=true;changeTime=true;">
                                        <v-icon x-small dark>mdi-pencil</v-icon>编辑
                                    </v-btn>
                                    <v-btn class="mx-2" style="margin:5px 0px;" dark small color="primary" @click="$router.push({path:'/collection',query:{id:item.nid,sid:item.id,name:item.key}})">
                                        <v-icon x-small dark>mdi-align-vertical-bottom</v-icon>统计
                                    </v-btn>
                                    <v-btn class="mx-2" dark small color="cyan" @click="$router.push({path:'/orderList',query:{id:form.nid,cid:item.id, name:item.key}})">
                                        <v-icon x-small dark>mdi-format-list-bulleted-square</v-icon>订单
                                    </v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" md="6" xs="12">
                                <v-pagination v-model="currentPage" :length="pageCount" total-visible="5" @input="getList"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="6" xs="12">
                                <v-select :items="limits" v-model="form.limit" label="条数" dense @change="limitChange"></v-select>
                            </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">编辑</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="changeEnable">
                                <v-col cols="12" sm="12" md="12">
                                    <v-select :items="enables" label="开启/关闭" v-model="editForm.enabled"></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="changeLevel">
                                <v-col cols="12" sm="12" md="12">
                                    <v-select :items="items" label="会员等级" v-model="editForm.level"></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="changeTime">
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.year" label="年" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.month" label="月" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.day" label="日" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.hour" label="时" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.minute" label="分" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field v-model="addForm.second" label="秒" type="number"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false;addForm={year:0, month:0, day:0, hour:0, minute:0, second:0};changeEnable=false;changeLevel=false;changeTime=false;editForm={}">取消</v-btn>
                        <v-btn color="blue darken-1" text @click="save">确定</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>

<script>
    // @ is an alias to /src
    import { post, tips, limits } from '@/facade';
    export default {
        data() {
            return {
                loading: true,
                singleExpand: true,
                form: {
                    nid: '',
                    limit: 20,
                    offset: 0,
                    type: 'device'
                },
                currentPage: 1,
                pageCount: 1,
                limits: limits,
                headers: [
                    { text: '账号', value: "key" },
                    { text: '等级', value: "level" },
                    { text: '到期时间', value: "expire" },
                    { text: '创建时间', value: "created_at" },
                    { text: '启用', value: "enabled" },
                    { text: '激活IP', value: "actv_ip" },
                    { text: '最后一次登陆IP', value: "dial_ip" },
                    { text: '管理', value: 'data-table-expand', width: '100' },
                ],
                editForm: {},
                addForm: {
                    year: 0,
                    month: 0,
                    day: 0,
                    hour: 0,
                    minute: 0,
                    second: 0
                },
                dialog: false,
                selected: [],
                name: "",
                days: [],
                totalCount: 0,
                items: [
                    { text: '普通会员', value: 1 },
                    { text: '超级会员', value: 2 },
                ],
                enables: [
                    { text: '批量开启', value: true },
                    { text: '批量关闭', value: false },
                ],
                level: '',
                nid: '',
                keys: "",
                changeEnable: false,
                changeLevel: false,
                changeTime: false
            }
        },
        watch: {
            '$route'(to, from) { // 监听路由是否变化
                if (to.query.id !== from.query.id) {
                    this.name = to.query.name;
                    this.form.nid = to.query.id;
                    this.getList()
                }
            }
        },
        async created() {
            const info = localStorage.getItem('netInfo')
            this.netInfo = JSON.parse(info);
            this.name = this.netInfo.name;
            this.form.nid = this.netInfo.id;
            this.form.type='uname'
            await this.getList()
        },
        computed: {
            robotList() {
                return this.$store.state.robotList;
            }
        },
        methods: {
            /**每页显示条数变化 */
            async limitChange() {
                this.currentPage = 1;
                this.getList();
            },
            // 获取设备列表
            async getList() {
                for (let item in this.form) {
                    if (!this.form[item]) {
                        delete this.form[item]
                    }
                }
                this.form.offset = this.form.limit * (this.currentPage - 1);
                const data = await post('/vnet/client/list', this.form)
                if (data.code == 'ok') {
                    this.loading = false;
                    this.totalCount = data.count;
                    let list = data.clients;
                    // list.forEach(item => item.addr = '')
                    this.$store.commit('changeRobotList', list)
                    this.pageCount = data.count > 0 ? Math.ceil(data.count / this.form.limit) : 1;
                } else {
                    this.loading = false;
                    tips('error', data.message)
                }
            },
            edit(item) {
                this.dialog = true;
                this.editForm.level = item.level
                this.editForm.nid = item.nid;
                this.editForm.cids = [item.id];
            },
            /**批量编辑 */
            batchEdit() {
                if (this.selected.length > 0) {
                    this.dialog = true;
                    this.editForm.nid = this.$route.query.id;
                    this.editForm.cids = this.selected.map(item => item.id)
                } else {
                    tips('error', '请先选择设备')
                }
            },
            /**编辑 */
            async save() {
                this.editForm.time = Object.values(this.addForm).map(item => item || 0).join(',');
                const form = Object.assign({}, this.editForm)
                const data = await post('/vnet/client/update', form)
                if (data.code == 'ok') {
                    tips('success', '编辑成功');
                    this.dialog = false;
                    this.changeEnable = false;
                    this.changeLevel = false;
                    this.changeTime = false;
                    this.editForm = {};
                    this.addForm = { year: 0, month: 0, day: 0, hour: 0, minute: 0, second: 0 },
                        await this.getList();
                } else {
                    tips('error', data.message)
                }
            },

            /**更改设备状态 */
            async changeStatus(item) {
                const value = await confirm('即将更改状态，是否继续')
                if (value) {
                    const form = { nid: item.nid, cids: [item.id], enabled: !!item.enabled }
                    const data = await post('/vnet/client/update', form)
                    if (data.code == 'ok') {
                        tips('success', '更改成功')
                        await this.getList();
                    } else {
                        tips('error', data.message)
                        await this.getList();
                    }
                } else {
                    await this.getList();
                }

            },

            /**搜索 */
            async search() {
                if (this.keys) this.form.keys = this.keys.split(',').map(item => item.trim())
                await this.getList()
            },



        },
    }
</script>

<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }

    }
</style>